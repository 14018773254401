import styled, { css, keyframes } from 'styled-components';
import BACKGROUND from '../../../../images/product-assets/fraud-prevention-bg.svg';
import { breakpoints } from '../../../../styles/screenSizes';

export const Wrapper = styled.section`
    max-width: 1280px;
    margin: auto;
    padding: 0px 24px 72px 24px;

    @media only screen and (max-width: ${breakpoints.md}) {
        padding: 0px 24px 82px 24px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        padding: 54px 16px 58px 16px;
    }
`;

export const Container = styled.div`
    background: url(${BACKGROUND}) center center/cover no-repeat;
    border: 1px solid #eaecf0;
    display: flex;
    border-radius: 32px;
    overflow: hidden;

    @media only screen and (max-width: ${breakpoints.sm}) {
        max-height: max-content;
        flex-direction: column-reverse;
        border-radius: 12px;
    }
`;

export const LeftSection = styled.div`
    flex: 1;
    padding: 43px 36px;
    height: fit-content;

    @media only screen and (max-width: ${breakpoints.lg}) {
        padding: 36px 24px;
    }

    @media only screen and (max-width: ${breakpoints.md}) {
        padding: 24px;
    }
    @media only screen and (max-width: ${breakpoints.sm}) {
        padding: 36px 23px;
    }
`;

export const Heading = styled.h1`
    color: #fff;
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;

    @media only screen and (max-width: ${breakpoints.lg}) {
        font-size: 24px;
        line-height: 32px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        text-align: center;
        font-size: 18px;
        line-height: 26.7px;
    }
`;

export const SubText = styled.p`
    margin-top: 20px;
    color: #fff;
    font-size: 18px;
    line-height: 27px;

    @media only screen and (max-width: ${breakpoints.lg}) {
        font-size: 14px;
        line-height: 22px;
        margin-top: 16px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        text-align: center;
    }
`;

export const CtaContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 32px;
    gap: 16px;

    @media only screen and (max-width: ${breakpoints.md}) {
        flex-direction: column;
        align-items: flex-start;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        align-items: center;
    }
`;

export const CommonButtonStyle = css`
    all: unset;
    padding: 4px 16px;
    border-radius: 4px;
    border: 1px solid #fff;
    font-size: 14px;
    line-height: 26.5px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        transform: scale(1.03);
    }

    @media only screen and (max-width: ${breakpoints.md}) {
        width: 160px;
        text-align: center;
    }
`;

export const DemoCta = styled.button`
    ${CommonButtonStyle}
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: #fff;
    color: #1d2939;
`;

export const StartCta = styled.a`
    ${CommonButtonStyle}
    background-color: transparent;
    color: #fff;
`;

export const RightSection = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    max-height: 340px;

    @media only screen and (max-width: ${breakpoints.md}) {
        max-height: 400px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        flex-direction: column-reverse;
        gap: 16px;
        margin-top: 36px;
    }
`;

export const MarqueeWrapper = styled.div`
    height: 100%;
    white-space: nowrap;
    transform: rotate(15deg);
    display: inline-flex;
    flex-direction: column;

    @media only screen and (max-width: ${breakpoints.sm}) {
        width: 100%;
        transform: rotate(0deg);
        display: block;
    }
`;

const ScrollY = keyframes`
    from{
        transform: translateY(0);
    }
    to {
        transform: translateY(-100%);
    }
`;

const ScrollYReverse = keyframes`
from{
    transform: translateY(-100%);
}
to {
    transform: translateY(0);
}
`;

const ScrollX = keyframes`
    from{
        transform: translateX(0);
    }to{
        transform: translateX(-100%);
    }
`;
const ScrollXReverse = keyframes`
      from{
        transform: translateX(-100%);
    }to{
        transform: translateX(0);
    }
`;
export const MarqueeGroup = styled.div`
    will-change: transform;
    animation: ${({ reverse }) => (reverse ? ScrollYReverse : ScrollY)} 10s linear infinite;

    @media only screen and (max-width: ${breakpoints.sm}) {
        display: inline-flex;
        animation: ${({ reverse }) => (reverse ? ScrollX : ScrollXReverse)} 10s linear infinite;
    }
`;

export const LogoContainer = styled.div`
    height: 100px;
    width: 100px;
    border-radius: 8px;
    background-color: #fff;
    display: grid;
    place-items: center;
    margin-top: 40px;
    flex-shrink: 0;
    overflow: hidden;

    @media only screen and (max-width: ${breakpoints.sm}) {
        margin-top: 0px;
        margin-right: 16px;
        height: 80px;
        width: 80px;
        border-radius: 5.76px;
    }
`;

export const Logo = styled.img`
    @media only screen and (max-width: ${breakpoints.sm}) {
        height: 80%;
        width: 80%;
    }
`;
