import React from 'react';
import RootLayout from '../../layouts/RootLayout';
import HeroSection from '../../sections/solutions/components/HeroSection';
import IFP_HERO_IMG from '../../images/product-assets/ifp-hero-image.svg';
import {
    COMMON_BOTTOM_BANNER_CONTENTS,
    IDENTITY_VERIFICATION,
    IFP_FAQs,
    IFP_RESOURCES,
    IFP_STEPS,
    IFP_VERIFICATION_PAGE_ANIMATED_TEXT,
    IFP_VERIFICATION_PAGE_HEADING,
    IFP_VERIFICATION_PAGE_SUB_HEADING,
    KYC_AML_AND_IFP_ADVANTAGES,
    THEFT_PREVENTION,
} from '../../sections/solutions/utilities';
import Vendors from '../../components/reusable/vendors/Index';
import Advantages from '../../sections/solutions/components/Advantages';
import ExpertSolutions from '../../sections/solutions/components/ExpertSolutions';
import { IFPBannerCta } from '../../sections/solutions/components/BannerCta';
import Banner from '../../sections/solutions/components/Banner';
import IFPBannerSubText from '../../sections/solutions/components/IFPBannerSubText';
import VerificationSteps from '../../sections/solutions/components/VerificationSteps';
import Resources from '../../components/reusable/Resources';
import ProductFaq from '../../sections/Product/components/common/ProductFaq';
import BottomBanner from '../../components/reusable/BottomBanner';
import MetaTagsProvider from '../../components/MetaTagsProvider';

const IdentityFraudPrevention = () => {
    return (
        <RootLayout getStartedCtaLink="https://portal.authenticate.com/register?utm_source=solutions&utm_medium=identity_fraud&utm_campaign=navbar">
            <MetaTagsProvider
                title="Authenticate: Advanced Identity Fraud Prevention Solutions"
                description="Identity Verification by Authenticate empowers businesses with AI-driven, real-time detection tools to prevent identity fraud. Our comprehensive fraud prevention solutions secure your operations and ensure compliance with KYC and AML regulations."
                ogImage="https://cdn.authenticating.com/public/website/product_pages/ifp-og-img.jpg"
                ogDescription="Identity Verification by Authenticate empowers businesses with AI-driven, real-time detection tools to prevent identity fraud. Our comprehensive fraud prevention solutions secure your operations and ensure compliance with KYC and AML regulations."
                path="solutions/identity-fraud-prevention"
                keywords="Identity Fraud Prevention, Fraud Detection, Fraud Prevention Solutions, Authenticate, Identity Verification, Fraud Mitigation, KYC Compliance, AML Compliance, Fraud Prevention Technology, Secure Transactions, Fraud Risk Management, Real-Time Fraud Detection, Fraud Prevention Software"
                twitterTitle="Authenticate: Leading Identity Fraud Prevention Solutions"
                twitterDescription="Stay ahead of fraudsters with Authenticate's advanced identity fraud prevention tools. Safeguard your business with real-time detection and compliance assurance."
            />
            <HeroSection
                category="Solutions"
                pageTitle="Identity Fraud Prevention"
                heroImage={IFP_HERO_IMG}
                textToAnimate={IFP_VERIFICATION_PAGE_ANIMATED_TEXT}
                heading={IFP_VERIFICATION_PAGE_HEADING}
                subHeading={IFP_VERIFICATION_PAGE_SUB_HEADING}
                getStartedCtaLink="https://portal.authenticate.com/register?utm_source=solutions&utm_medium=identity_fraud&utm_campaign=hero"
            />
            <Vendors />
            <Advantages content={KYC_AML_AND_IFP_ADVANTAGES} />
            <ExpertSolutions
                heading="Simplify Identity Verification"
                data1={IDENTITY_VERIFICATION}
                data2={THEFT_PREVENTION}
                link1="/products/identity"
                link2="/products/criminal-background-checks"
            />
            <Banner
                heading="Optimize Your Workflow with Fast and Scalable Identity Verification API"
                subText={<IFPBannerSubText />}
                ctaComponent={<IFPBannerCta />}
            />
            <VerificationSteps data={IFP_STEPS} />
            <Resources item={IFP_RESOURCES} />
            <ProductFaq
                faqData={IFP_FAQs}
                titleColor="#262626"
                animation
                subHeading="Everything you need to know about Identity Fraud Prevention."
            />
            <BottomBanner
                data={COMMON_BOTTOM_BANNER_CONTENTS}
                getStartedCtaLink="https://portal.authenticate.com/register?utm_source=solutions&utm_medium=identity_fraud&utm_campaign=footer"
            />
        </RootLayout>
    );
};

export default IdentityFraudPrevention;
