import axios from 'axios';
import { endpoints } from '../endpoints';

const baseUrl = process.env.GATSBY_BACK_END_API_BASE_URL;

export const whitepaperApi = async (email) => {
    const response = await axios.post(
        `${baseUrl}${endpoints.whitepaper}`,
        { email },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    return response.data;
};
