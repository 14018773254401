// import React from "react";
import styled, { css } from "styled-components";

export const Button = styled.button`
	font-family: "Rubik";
	font-size: 14px;
	padding: 12px 24px;
	border-radius: 6px;
	background: #212121;
	color: white;
	border: none;
	cursor: pointer;

	${({ secondary }) =>
		secondary &&
		css`
			border: 1px solid rgba(0, 0, 0, 0.5);
			background: none;
			color: #212121;
			display: inline-block;
		`}

	${({ submitBtn }) =>
		submitBtn &&
		css`
			width: 183px;
			text-align: center;
			display: block;
		`}

		${({ large }) =>
		large &&
		css`
			font-size: 16px;
		`}

	margin: ${({ margin }) => margin || "0"};
`;
