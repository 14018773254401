import React from 'react';
import styled from 'styled-components';
import { complianceMeasuresData } from '../constants/constant.js';
import ComplianceMeasuresCard from '../components/ComplianceMeasuresCard.jsx';

const Container = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    padding: 84px 28px 40px 28px;
    gap: 44px;

    @media only screen and (max-width: 820px) {
        padding: 72px 28px 40px 28px;
    }
    @media only screen and (max-width: 560px) {
        gap: 32px;
    }
`;

const Heading = styled.h1`
    font-size: 42px;
    font-weight: 500;
    line-height: 49.77px;
    text-align: center;
    width: 538px;
    margin: 0 auto;
    color: #1d2939;

    @media only screen and (max-width: 820px) {
        font-size: 32px;
        line-height: 37.92px;
    }

    @media only screen and (max-width: 560px) {
        font-size: 24px;
        line-height: 28.44px;
        width: 281px;
    }
`;

const DataGrid = styled.div`
    display: grid;
    grid-template-columns: minmax(0, 0.8fr) minmax(0, 1fr);
    grid-column-gap: 44px;
    grid-row-gap: 36px;
    place-content: center;

    @media only screen and (max-width: 820px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 24px;
    }
    @media only screen and (max-width: 560px) {
        grid-template-columns: 1fr;
    }
`;

const ComplianceMeasures = () => {
    return (
        <Container>
            <Content>
                <Heading>Compliance Measures at Authenticate</Heading>
                <DataGrid>
                    {complianceMeasuresData.map((data, index) => (
                        <ComplianceMeasuresCard key={`${data.title}-${index}`} data={data} />
                    ))}
                </DataGrid>
            </Content>
        </Container>
    );
};

export default ComplianceMeasures;
