import React from 'react';
import styled, { css } from 'styled-components';
import GUESTY_LOGO from '../../../images/product-assets/guesty_logo-colored.svg';
import HOSTAWAY_LOGO from '../../../images/product-assets/hostaway-logo.svg';
import LODGIFY_LOGO from '../../../images/product-assets/lodgify_logo.svg';
import OWNERREZ_LOGO from '../../../images/product-assets/ownerrez_logo.png';
import GRID_BG from '../../../images/product-assets/grid.svg';
import { useModal } from '../../../context/ModalContext';
import { breakpoints } from '../../../styles/screenSizes';
import { PORTAL_STR_LINKS } from '../utilities';

const Wrapper = styled.section`
    padding: 110px 24px 0 24px;
    max-width: 1280px;
    margin: auto;

    @media only screen and (max-width: 850px) {
        padding: 72px 24px 0 24px;
    }

    @media only screen and (max-width: 600px) {
        padding: 54px 14px 0 14px;
    }
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 32px;
    border: 1px solid #f5fbff;
    border-radius: 24px;
    padding: 32px 99px;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        height: 868px;
        width: 100%;
        top: -320px;
        left: 50%;
        bottom: 0px;
        background: url(${GRID_BG}) center center/cover no-repeat;
        z-index: -1;
        transform: translateX(-50%) rotate(180deg);
        border-radius: inherit;
        opacity: 0.6;
    }

    @media only screen and (max-width: 850px) {
        padding: 24px 32px;

        &::before {
            height: 600px;
            width: 100%;
            top: -225px;
        }
    }

    @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        border-radius: 12px;
        padding: 16px;

        &::before {
            top: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
        }
    }
`;

const HeadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    @media only screen and (max-width: ${breakpoints.md}) {
        gap: 8px;
    }
`;

const Heading = styled.h1`
    font-size: 32px;
    line-height: 40px;
    color: #1d2939;
    font-weight: 500;

    @media only screen and (max-width: 850px) {
        font-size: 22px;
        line-height: 27.5px;
    }
`;

const SubText = styled.p`
    font-size: 18px;
    line-height: 27px;
    color: #1d2939;
    font-weight: 400;
    max-width: 663px;
    text-align: center;

    @media only screen and (max-width: 850px) {
        font-size: 14px;
        line-height: 21px;
        max-width: 467px;
    }
`;
const IntegrationContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: ${breakpoints.sm}) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 12px;

        > div:where(:nth-child(1), :nth-child(2)) {
            flex: 0 0 200px;
        }
    }
`;

const Integration = styled.div`
    width: 100%;
    height: 80px;
    max-width: ${({ isComingSoon }) => (isComingSoon ? '102px' : '196px')};
    background-color: ${({ bgColor }) => bgColor || '#fff'};
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    position: relative;

    ${({ isComingSoon }) =>
        isComingSoon &&
        css`
            display: grid;
            place-items: center;
            font-size: 12px;
            line-height: 16px;
            font-weight: 500;
            color: #0ba5ec;
        `}

    @media only screen and (max-width: ${breakpoints.md}) {
        height: 52px;
        max-width: ${({ isComingSoon }) => (isComingSoon ? '52px' : '132px')};
        padding: 12.25px 10px;

        ${({ isComingSoon }) =>
            isComingSoon &&
            css`
                font-size: 10px;
                line-height: 11.85px;
            `};
    }
`;

const LogoContainer = styled.div`
    height: 100%;
    width: 100%;
    border-radius: 5.98px;
    background-color: ${({ bgColor }) => bgColor || '#fff'};
    display: grid;
    place-items: center;
    padding: 5.65px 17px;

    @media only screen and (max-width: ${breakpoints.md}) {
        padding: 2px 14px;
        border-radius: 4.05px;
    }
`;

const Logo = styled.img`
    height: auto;
    width: 100%;
`;

const JoinCta = styled.a`
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 11px;
    text-transform: uppercase;
    color: ${({ color }) => color || '#fff'};
    background-color: none;
    outline: none;
    border: none;
    text-align: center;
    width: 100%;

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: 8px;
    }
`;

const BottomText = styled.p`
    font-size: 14px;
    line-height: 21px;
    color: #1d2939;
    font-weight: 400;
    padding-top: 18px;

    @media only screen and (max-width: 850px) {
        font-size: 12px;
        line-height: 18px;
        max-width: 400px;
        text-align: center;
    }

    @media only screen and (max-width: 600px) {
        padding-top: 0px;
    }
`;

const Button = styled.button`
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    color: #1570ef;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
    background: none;
    border: none;

    @media only screen and (max-width: 850px) {
        font-size: 12px;
    }
`;

const IntegrationBanner = () => {
    const { openModal } = useModal();

    const handleIntegrationClick = (e, url) => {
        e.preventDefault();
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    return (
        <Wrapper>
            <Container>
                <HeadingContainer>
                    <Heading>Automated Guest Screenings</Heading>
                    <SubText>
                        Partner up with our PMS partners to fully automate and revolutionize your
                        guest verification experience.
                    </SubText>
                </HeadingContainer>
                <IntegrationContainer>
                    <Integration
                        bgColor="#EFF8FF"
                        onClick={(e) => handleIntegrationClick(e, PORTAL_STR_LINKS.GUESTY)}
                    >
                        <LogoContainer bgColor="#10275B">
                            <Logo src={GUESTY_LOGO} alt="guesty-logo" />
                        </LogoContainer>
                    </Integration>
                    <Integration
                        bgColor="#FFF6ED"
                        onClick={(e) => handleIntegrationClick(e, PORTAL_STR_LINKS.HOSTAWAY)}
                    >
                        <LogoContainer bgColor="#FF6A0A">
                            <Logo src={HOSTAWAY_LOGO} alt="hostaway-logo" />
                        </LogoContainer>
                    </Integration>
                    <Integration
                        bgColor="#E9FFFB"
                        onClick={(e) => handleIntegrationClick(e, PORTAL_STR_LINKS.LODGIFY)}
                    >
                        <LogoContainer>
                            <Logo src={LODGIFY_LOGO} alt="lodgify-logo" />
                        </LogoContainer>
                    </Integration>
                    <Integration bgColor="#F6FEF9" onClick={() => openModal('ownerRez')}>
                        <LogoContainer>
                            <Logo src={OWNERREZ_LOGO} alt="ownerRez-logo" />
                        </LogoContainer>
                        <JoinCta color="#245E24" type="button">
                            Join Waitlist
                        </JoinCta>
                    </Integration>
                    <Integration bgColor="#F5FBFF" isComingSoon>
                        More coming soon!
                    </Integration>
                </IntegrationContainer>
                <BottomText>
                    Don't see your favorite PMS?{' '}
                    <Button type="button" onClick={() => openModal('pms')}>
                        Click here
                    </Button>{' '}
                    and we’ll make it happen.
                </BottomText>
            </Container>
        </Wrapper>
    );
};

export default IntegrationBanner;
