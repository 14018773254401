import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { Heading } from './components/styles';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
import isMobile from 'is-mobile';
import { useIsomorphicLayoutEffect } from '../../hooks/useIsomorphicLayoutEffect';
//Images
import Master_matchmaker from '../../images/logos/master_matchmaker.svg';
import Nanno from '../../images/logos/nanno.png';
import Hyreshift from '../../images/logos/hypershift.svg';
import ScreenOne_1 from '../../images/mastermatchmaker_screen_1.svg';
import ScreenOne_2 from '../../images/mastermatchmaker_screen_2.svg';
import ScreenTwo_1 from '../../images/nanno_screen_1.svg';
import ScreenTwo_2 from '../../images/nanno_screen_2.svg';
import ScreenThree_1 from '../../images/hypershift_screen_1.svg';
import ScreenThree_2 from '../../images/hypershift_screen_2.svg';

const Container = styled.section`
    max-width: 1280px;
    margin: 72px auto;
    padding: 0 92px;
    overflow-x: hidden;

    @media screen and (max-width: 500px) {
        padding: 0 16px;
    }
`;
const Section = styled.div`
    background: linear-gradient(to right, rgba(37, 41, 99, 1), rgba(70, 148, 196, 0.73));
    border-radius: 11px;
    padding: 39px 0 120px;

    br {
        display: none;
    }

    @media screen and (max-width: 820px) {
        padding-bottom: 43px;

        br {
            display: block;
        }
    }
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center center;
    margin-top: 100px;
    overflow-x: hidden;

    @media screen and (max-width: 820px) {
        margin-top: 50px;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;

        :nth-child(1) {
            order: 2;
        }

        :nth-child(2) {
            order: 1;
            justify-content: center;
        }
    }
`;

const GridSection = styled.div`
    overflow: hidden;
    @media screen and (max-width: 820px) {
        :nth-child(1) {
            order: 2;
        }

        :nth-child(2) {
            order: 1;
        }
    }
`;

const Card = styled.div`
    padding: 20px;
    background: #fff;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    width: 303px;
    height: 110px;
    align-items: center;
    margin-top: ${(props) => (props.first ? '0' : '24px')};
    /* position: ${(props) => (props.first ? 'relative' : 'none')}; */
    position: relative;
    cursor: pointer;
    overflow: hidden;

    @media screen and (max-width: 820px) {
        padding: 14px;
        margin-top: 0;
        width: 220px;
        height: 80px;
        flex-shrink: 0;
    }
`;
const CardLogo = styled.img`
    width: auto;

    @media screen and (max-width: 820px) {
        transform: scale(0.8);
        margin: 0;
    }
`;

const CardText = styled.p`
    font-size: 12px;
    font-family: 'Rubik';
    overflow-wrap: break-word;
    margin-left: 20px;

    @media screen and (max-width: 820px) {
        font-size: 8px;
        margin-left: 12px;
    }
`;

const ProgressBar = styled.div`
    width: 100%;
    height: 4px;
    background: rgba(98, 152, 234, 1);
    border-radius: 6px;
    position: absolute;
    bottom: 0;
    left: 0;
`;

const fadeInContainer = keyframes`
 0% {opacity:0;}
  100% {opacity:1;}
`;

const ScreenContainer = styled.div`
    display: ${({ visible }) => (visible ? 'grid' : 'none')};
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    animation: ${fadeInContainer} 3s;
    transition: all 0.3s;
`;
const Screens = styled.img`
    @media screen and (max-width: 500px) {
        width: 137px;
        height: auto;
    }
`;

const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 820px) {
        flex-direction: row;
        margin-top: 50px;
        padding: 0 16px;
        gap: 12px;
        overflow-x: scroll;
        width: 550px;
        ::-webkit-scrollbar {
            display: none;
        }
    }

    @media screen and (max-width: 500px) {
        width: 320px;
    }
`;

function Experiences() {
    const [screenImg, setScreenImg] = useState('master_matchmaker');

    const [firstProgress, setFirstProgress] = useState(null);
    const [secondProgress, setSecondProgress] = useState(null);
    const [thirdProgress, setThirdProgress] = useState(null);

    const resetProgress = (progress) => {
        if (!progress) return;
        progress.pause();
        progress.time(0);
    };
    const startProgress = (progress) => {
        progress.play();
    };

    const firstRef = useRef(null);
    const secondRef = useRef(null);
    const thirdRef = useRef(null);
    const timer = useRef(null);
    const experienceSectionRef = useRef();
    const delay = 5;

    const getAnimation = (ref) =>
        gsap.from(ref.current, {
            scaleX: 0,
            transformOrigin: '0px 0px',
            ease: 'none',
            duration: delay,
            paused: true,
        });

    useEffect(() => {
        setFirstProgress(getAnimation(firstRef));
    }, [firstRef]);
    useEffect(() => {
        setSecondProgress(getAnimation(secondRef));
    }, [secondRef]);
    useEffect(() => {
        setThirdProgress(getAnimation(thirdRef));
    }, [thirdRef]);
    useEffect(() => {}, []);

    const startAnimation = () => {
        if (timer.current) {
            clearInterval(timer.current);
        }
        timer.current = loopInterval('master_matchmaker');
    };

    let screenToProgress = {
        master_matchmaker: firstProgress,
        nanno: secondProgress,
        hyreshift: thirdProgress,
    };
    let screenList = ['master_matchmaker', 'nanno', 'hyreshift'];

    useIsomorphicLayoutEffect(() => {
        let scrollTrigger;
        if (
            experienceSectionRef.current &&
            firstProgress &&
            secondProgress &&
            thirdProgress &&
            !timer.current
        ) {
            gsap.registerPlugin(ScrollTrigger);
            scrollTrigger = ScrollTrigger.create({
                trigger: '#' + experienceSectionRef.current.id,
                pin: false, // pin the trigger element while active
                start: 'top bottom-=150px', // when the top of the trigger hits the top of the viewport
                end: '+=600', // end after scrolling 500px beyond the start
                onEnter: () => startAnimation(),
                //   onLeave: () => timer.current ? clearInterval(timer.current) : null,
                onEnterBack: () => startAnimation(),
                //   onLeaveBack: ()=> timer.current ? clearInterval(timer.current) : null
            });
        }

        return function cleanup() {
            if (scrollTrigger?.kill) scrollTrigger.kill();
            if (timer.current) {
                clearInterval(timer.current);
            }
        };
    }, [experienceSectionRef, firstProgress, secondProgress, thirdProgress]);

    const resetAllProgressBars = () => {
        resetProgress(firstProgress);
        resetProgress(secondProgress);
        resetProgress(thirdProgress);
    };

    const loop = () => {
        resetAllProgressBars();

        let currentScreen;
        setScreenImg((screen) => {
            currentScreen = screen;
            return screen;
        });
        let nextIndex = (screenList.indexOf(currentScreen) + 1) % 3;
        setScreenImg(screenList[nextIndex]);
        startProgress(screenToProgress[screenList[nextIndex]]);

        cardLoop(nextIndex);
    };

    // Memoized because it has been added to the useMemo function
    const loopInterval = (startScreen) => {
        resetAllProgressBars();
        startProgress(screenToProgress[startScreen]);
        setScreenImg(startScreen);

        let intervalId = setInterval(function () {
            loop();
        }, delay * 1000);
        return intervalId;
    };

    const onCardClick = (screenName) => () => {
        if (timer.current) {
            clearInterval(timer.current);
        }
        timer.current = loopInterval(screenName);
    };

    const scrollCardRef = useRef(null);
    const firstCardRef = useRef(null);
    const secondCardRef = useRef(null);
    const thirdCardRef = useRef(null);

    let cardToSlide = {
        master_matchmaker: firstCardRef.current,
        nanno: secondCardRef.current,
        hyreshift: thirdCardRef.current,
    };

    const cardLoop = (nextIndex) => {
        gsap.to(scrollCardRef.current, {
            //screenList array contain all cards name
            scrollTo: {
                x: cardToSlide[screenList[nextIndex]],
                offsetX: 20,
            },
        });
    };

    useIsomorphicLayoutEffect(() => {
        gsap.registerPlugin(ScrollToPlugin);

        if (isMobile()) {
            return cardLoop();
        }
    }, []);

    return (
        <Container ref={experienceSectionRef} id="experienceSection">
            <Section>
                <Heading
                    secondary
                    center
                    padding="0px 32px"
                    style={{ color: 'white', marginTop: '1rem', fontSize: '36px' }}
                >
                    Easily Configure Branding & Workflows
                </Heading>
                <GridContainer>
                    <GridSection>
                        <CardWrapper ref={scrollCardRef}>
                            <Card
                                first
                                onClick={onCardClick('master_matchmaker')}
                                ref={firstCardRef}
                            >
                                <CardLogo
                                    src={Master_matchmaker}
                                    alt="Master Matchmaker"
                                    width="auto"
                                    height="auto"
                                    loading="lazy"
                                />
                                <CardText>
                                    Age verification, national criminal checks and enhanced due
                                    diligence.
                                </CardText>

                                <ProgressBar
                                    className="progress"
                                    id="card1"
                                    ref={firstRef}
                                ></ProgressBar>
                            </Card>
                            <Card onClick={onCardClick('nanno')} ref={secondCardRef}>
                                <CardLogo
                                    src={Nanno}
                                    alt="Nanno"
                                    width="auto"
                                    height="auto"
                                    loading="lazy"
                                />
                                <CardText>
                                    SSN trace, global watch list checks, sex offender search, county
                                    criminal history and national criminal checks.
                                </CardText>

                                <ProgressBar
                                    className="progress"
                                    ref={secondRef}
                                    id="card2"
                                ></ProgressBar>
                            </Card>
                            <Card onClick={onCardClick('hyreshift')} ref={thirdCardRef}>
                                <CardLogo
                                    src={Hyreshift}
                                    alt="Hyreshift"
                                    width="auto"
                                    height="auto"
                                    loading="lazy"
                                />
                                <CardText>
                                    Employment and education verification, criminal history and
                                    global watchlist searches.
                                </CardText>

                                <ProgressBar
                                    className="progress"
                                    ref={thirdRef}
                                    id="card3"
                                ></ProgressBar>
                            </Card>
                        </CardWrapper>
                    </GridSection>
                    <GridSection>
                        <ScreenContainer visible={'master_matchmaker' === screenImg}>
                            <Screens
                                src={ScreenOne_1}
                                alt="Screen1"
                                width="auto"
                                height="auto"
                                loading="lazy"
                            />
                            <Screens
                                src={ScreenOne_2}
                                alt="Screen2"
                                width="auto"
                                height="auto"
                                loading="lazy"
                            />
                        </ScreenContainer>
                        <ScreenContainer visible={'nanno' === screenImg}>
                            <Screens
                                src={ScreenTwo_1}
                                alt="Screen1"
                                width="auto"
                                height="auto"
                                loading="lazy"
                            />
                            <Screens
                                src={ScreenTwo_2}
                                alt="Screen2"
                                width="auto"
                                height="auto"
                                loading="lazy"
                            />
                        </ScreenContainer>
                        <ScreenContainer visible={'hyreshift' === screenImg}>
                            <Screens
                                src={ScreenThree_1}
                                alt="Screen1"
                                width="auto"
                                height="auto"
                                loading="lazy"
                            />
                            <Screens
                                src={ScreenThree_2}
                                alt="Screen2"
                                width="auto"
                                height="auto"
                                loading="lazy"
                            />
                        </ScreenContainer>
                    </GridSection>
                </GridContainer>
            </Section>
        </Container>
    );
}

export default Experiences;
