import React, { useEffect, useRef } from 'react';
import {
    Bullet,
    BulletIconContainer,
    Container,
    Desc,
    Heading,
    IconContainer,
    LeftSection,
    Points,
    RightSection,
    Text,
} from './idFrauds.style';
import CheckMarkIcon from '../../../../images/Icons/icon-checkmark-styled.svg';
import PropTypes from 'prop-types';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { useMediaQuery } from '@mui/material';

gsap.registerPlugin(ScrollTrigger);

const IdFrauds = ({ data, reverse = false }) => {
    const leftSectionRef = useRef(null);
    const rightSectionRef = useRef(null);
    const containerRef = useRef(null);
    const isSmallScreen = useMediaQuery('(max-width: 600px)');

    useEffect(() => {
        const timeline = gsap.timeline();

        if (isSmallScreen) {
            timeline.fromTo(
                containerRef.current,
                { x: reverse ? -60 : 60, opacity: 0 },
                {
                    x: 0,
                    opacity: 1,
                    duration: 0.4,
                    ease: 'none',
                    scrollTrigger: {
                        trigger: containerRef.current,
                        start: 'top 80%',
                    },
                }
            );
        } else {
            timeline
                .fromTo(
                    leftSectionRef?.current,
                    { x: reverse ? 60 : -60, opacity: 0 },
                    {
                        x: 0,
                        opacity: 1,
                        duration: 0.4,
                        ease: 'none',
                        scrollTrigger: {
                            trigger: leftSectionRef?.current,
                            start: 'top 50%',
                        },
                    }
                )
                .fromTo(
                    rightSectionRef?.current,
                    { x: reverse ? -60 : 60, opacity: 0 },
                    {
                        x: 0,
                        opacity: 1,
                        duration: 0.4,
                        ease: 'none',
                        scrollTrigger: {
                            trigger: rightSectionRef?.current,
                            start: 'top 50%',
                        },
                    },
                    '-=0.3'
                );
        }

        // Cleanup function
        return () => {
            timeline?.kill();
            ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
        };
    }, [reverse]);

    return (
        <Container reverse={reverse} ref={containerRef}>
            <LeftSection ref={leftSectionRef}>
                <IconContainer>
                    <img src={data.icon} alt="icon" />
                </IconContainer>
                <Heading>{data.heading}</Heading>
                <Desc>{data.desc}</Desc>
                <Points>
                    {data?.points?.map((point) => (
                        <Bullet key={point}>
                            <BulletIconContainer>
                                <img src={CheckMarkIcon} alt="check-mark-icon" />
                            </BulletIconContainer>
                            <Text>{point}</Text>
                        </Bullet>
                    ))}
                </Points>
            </LeftSection>
            <RightSection ref={rightSectionRef}>
                <img src={data?.img} alt="data illustration" />
            </RightSection>
        </Container>
    );
};

IdFrauds.propTypes = {
    data: PropTypes.object,
    reverse: PropTypes.bool,
};

export default IdFrauds;
