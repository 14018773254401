import React, { useRef, useState, useEffect, useCallback } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import CheckMarkIcon from '../../../../images/Icons/icon-checkmark-styled.svg';
import ArrowRight from '../../../../images/Icons/at_chevron-right.svg';
import { useModal } from '../../../../context/ModalContext';
import {
    Box,
    Bullet,
    ContactCta,
    Container,
    Contents,
    Cta,
    Desc,
    Div,
    Heading,
    HiddenContents,
    IconContainer,
    Img,
    LeftDiv,
    Pointers,
    ProgressBar,
    RightDiv,
    Text,
} from './progressBarCarousal.style';

gsap.registerPlugin(ScrollTrigger);

const ProgressBarCarousal = ({ items }) => {
    const { openModal } = useModal();

    const [activeIndex, setActiveIndex] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);

    const progressRefs = useRef([]);
    const animations = useRef([]);
    const containerRef = useRef([]);
    const mainSectionRef = useRef(null);
    const leftDivRef = useRef(null);
    const rightDivRef = useRef(null);

    const delay = 8;

    const handleCardClick = (index) => {
        setActiveIndex(index);
        startProgress(index);
    };

    useEffect(() => {
        animations.current = progressRefs.current?.map((ref) => {
            if (ref) {
                return gsap.fromTo(
                    ref,
                    {
                        scaleX: 0,
                    },
                    {
                        scaleX: 1,
                        transformOrigin: '0px 0px',
                        ease: 'none',
                        duration: delay,
                        paused: true,
                    }
                );
            }
            return null;
        });
    }, []);

    const resetAllProgressBars = useCallback(() => {
        animations.current?.forEach((animation) => {
            if (animation) {
                animation?.pause();
                animation?.time(0);
            }
        });
    }, []);

    const startProgress = useCallback(
        (index) => {
            resetAllProgressBars();
            if (animations.current[index]) {
                animations.current[index].play();
            }
        },
        [resetAllProgressBars]
    );

    const loopAnimation = useCallback(() => {
        const nextIndex = (activeIndex + 1) % items.length;
        setActiveIndex(nextIndex);
        startProgress(nextIndex);
    }, [activeIndex, startProgress, items.length]);

    useEffect(() => {
        let timer = null;
        if (mainSectionRef?.current && containerRef?.current) {
            ScrollTrigger.create({
                trigger: mainSectionRef.current,
                start: 'top 50%',
                onEnter: () => {
                    resetAllProgressBars();
                    startProgress(activeIndex);
                    timer = setInterval(() => {
                        loopAnimation();
                    }, delay * 1000);
                },
                onLeave: () => {
                    resetAllProgressBars();
                    if (timer) clearInterval(timer);
                },
                onLeaveBack: () => {
                    resetAllProgressBars();
                    setActiveIndex(0);
                    startProgress(0);
                    if (timer) clearInterval(timer);
                },
            });
        }

        return () => {
            if (timer) clearInterval(timer);
            ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
        };
    }, [startProgress, loopAnimation, resetAllProgressBars, activeIndex]);

    useEffect(() => {
        const calculatedHeights = containerRef.current?.map((el) => el?.scrollHeight || 0);
        setContainerHeight(calculatedHeights);
    }, [items]);

    return (
        <Container ref={mainSectionRef}>
            <LeftDiv ref={leftDivRef}>
                {items?.map((data, index) => {
                    const isActive = activeIndex === index;
                    const dynamicHeight = containerHeight[index] ?? 'max-content';
                    return (
                        <Box key={data.id} onClick={() => handleCardClick(index)}>
                            <Div>
                                <IconContainer>
                                    <img src={data.icon} alt="icon" />
                                </IconContainer>
                                <Contents>
                                    <Heading isActive={isActive}>{data.heading}</Heading>
                                    <HiddenContents
                                        ref={(el) => (containerRef.current[index] = el)}
                                        height={isActive ? dynamicHeight : 0}
                                    >
                                        <Desc>{data.desc}</Desc>
                                        <Pointers>
                                            {data?.points.map((point, index) => (
                                                <div key={index + point?.slice(0, 20)}>
                                                    <Bullet>
                                                        <img
                                                            src={CheckMarkIcon}
                                                            alt="checkmark-icon"
                                                        />
                                                    </Bullet>
                                                    <Text>{point}</Text>
                                                </div>
                                            ))}
                                        </Pointers>
                                        {data?.cta?.isPopup ? (
                                            <ContactCta onClick={() => openModal('contact')}>
                                                <p>{data?.cta?.text}</p>
                                                <img src={ArrowRight} alt="arrow-right-icon" />
                                            </ContactCta>
                                        ) : (
                                            <Cta
                                                href={data.cta.link}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <p>{data.cta.text}</p>
                                                <img src={ArrowRight} alt="arrow-right-icon" />
                                            </Cta>
                                        )}
                                    </HiddenContents>
                                </Contents>
                            </Div>
                            <ProgressBar>
                                <div ref={(el) => (progressRefs.current[index] = el)} />
                            </ProgressBar>
                        </Box>
                    );
                })}
            </LeftDiv>
            <RightDiv ref={rightDivRef}>
                {items?.map((data, index) => (
                    <Img
                        src={data.img}
                        alt={data.heading}
                        key={data.id}
                        isVisible={activeIndex === index}
                    />
                ))}
            </RightDiv>
        </Container>
    );
};

ProgressBarCarousal.propTypes = {
    items: PropTypes.array.isRequired,
};
export default ProgressBarCarousal;
