import styled, { css } from 'styled-components';
import { breakpoints } from '../../../../styles/screenSizes';

export const Container = styled.div`
    display: flex;
    gap: 36px;
    flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};

    @media only screen and (max-width: ${breakpoints.sm}) {
        flex-direction: column-reverse;
        border: 1px solid #eaecf0;
        border-radius: 16px;
        padding: 24px 16px;
    }
`;

const SharedBorderStyle = css`
    border: 1px solid #eaecf0;
    border-radius: 16px;

    @media only screen and (max-width: ${breakpoints.sm}) {
        border: none;
    }
`;

export const LeftSection = styled.div`
    ${SharedBorderStyle}
    padding: 24px 32px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media only screen and (max-width: ${breakpoints.md}) {
        padding: 16px 24px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        padding: 0px;
    }
`;

export const IconContainer = styled.div`
    border: 0.73px solid #eaecf0;
    border-radius: 5.82px;
    height: 32px;
    width: 32px;
    display: grid;
    place-items: center;

    > img {
        filter: invert(32%) sepia(18%) saturate(484%) hue-rotate(175deg) brightness(92%)
            contrast(87%);
    }
`;

export const Heading = styled.h1`
    font-size: 32px;
    line-height: 40px;
    color: #1d2939;
    font-weight: 500;

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: 18px;
        line-height: 22.5px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        font: 16px;
        line-height: 20px;
    }
`;

export const Desc = styled.p`
    font-size: 16px;
    line-height: 24px;
    color: #1d2939;
    font-weight: 400;

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: 14px;
        line-height: 21px;
    }
`;

export const Points = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;

    @media only screen and (max-width: ${breakpoints.md}) {
        grid-template-columns: 1fr;
    }
`;

export const Bullet = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const BulletIconContainer = styled.div`
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #e0f2fe;
    display: grid;
    place-items: center;
    flex-shrink: 0;

    > img {
        filter: invert(39%) sepia(24%) saturate(3375%) hue-rotate(170deg) brightness(95%)
            contrast(102%);
    }
`;

export const Text = styled.p`
    font-size: 14px;
    line-height: 19.6px;
    color: #475467;
`;

export const RightSection = styled.div`
    ${SharedBorderStyle}
    flex: 0.7;
    display: grid;
    place-items: center;

    > img {
        transition: transform 0.3s ease-in-out;
    }

    &:hover > img {
        transform: scale(1.03);
    }

    @media only screen and (max-width: ${breakpoints.md}) {
        flex: 0.85;

        img {
            height: 100%;
            width: 90%;
        }
    }
`;
