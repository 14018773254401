import React from 'react';
import {
    Container,
    CtaContainer,
    DemoCta,
    Heading,
    LeftSection,
    Logo,
    LogoContainer,
    MarqueeGroup,
    MarqueeWrapper,
    RightSection,
    StartCta,
    SubText,
    Wrapper,
} from './fraudPreventionBanner.style';
import ArrowRight from '../../../../images/product-assets/arrow-right-dark.svg';
import { VENDORS } from '../../../../helper/constant';
import { useModal } from '../../../../context/ModalContext';

const FraudPreventionBanner = () => {
    const { openModal } = useModal();
    return (
        <Wrapper>
            <Container>
                <LeftSection>
                    <Heading>Optimize Your Fraud Prevention in just 30 Seconds!</Heading>
                    <SubText>
                        Looking to safeguard your business from identity fraud without breaking the
                        bank? With no monthly minimums and pay-as-you-go, we've got you covered.
                        Curious to see how we can help?
                    </SubText>
                    <CtaContainer>
                        <DemoCta type="button" onClick={() => openModal('contact')}>
                            <p>Schedule a Demo</p>
                            <img src={ArrowRight} alt="arrow-right" />
                        </DemoCta>
                        <StartCta
                            href="https://portal.authenticate.com/register?utm_source=industry&utm_medium=finance&utm_campaign=lander"
                            rel="noreferrer"
                            target="_blank"
                        >
                            Get Started
                        </StartCta>
                    </CtaContainer>
                </LeftSection>
                <RightSection>
                    <MarqueeWrapper>
                        {Array.from({ length: 2 }).map((_, index) => (
                            <MarqueeGroup key={index + 1}>
                                {VENDORS.slice(0, 6).map((vendor) => (
                                    <LogoContainer key={vendor.id}>
                                        <Logo src={vendor.logo} alt={`${vendor.name} logo`} />
                                    </LogoContainer>
                                ))}
                            </MarqueeGroup>
                        ))}
                    </MarqueeWrapper>
                    <MarqueeWrapper>
                        {Array.from({ length: 2 }).map((_, index) => (
                            <MarqueeGroup key={index + 1} reverse>
                                {VENDORS.slice(6).map((vendor) => (
                                    <LogoContainer key={vendor.id}>
                                        <Logo src={vendor.logo} alt={`${vendor.name} logo`} />
                                    </LogoContainer>
                                ))}
                            </MarqueeGroup>
                        ))}
                    </MarqueeWrapper>
                </RightSection>
            </Container>
        </Wrapper>
    );
};

export default FraudPreventionBanner;
