import styled from 'styled-components';
import { breakpoints } from '../../../../styles/screenSizes';

export const Wrapper = styled.section`
    max-width: 1280px;
    margin: 0 auto;
    padding: 150px 24px 65px;
    background-color: #fff;

    @media only screen and (max-width: 768px) {
        padding: 100px 24px 50px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        padding: 100px 20px 36px;
    }
`;

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
    }
`;

export const Contents = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 768px) {
        align-items: center;
        text-align: center;
    }
`;

export const PageTitle = styled.p`
    font-size: 12px;
    font-weight: 400;
    line-height: 14.22px;
    color: #344054;
`;

export const Heading = styled.h1`
    margin-top: 10px;
    font-size: 50px;
    font-weight: 500;
    line-height: 60px;
    color: #1d2939;
    text-wrap: balance;

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: 32px;
        line-height: 38.4px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        text-align: center;
        max-width: 80%;
        font-size: 28px;
        line-height: 33.18px;
    }
`;

export const FormContainer = styled.div`
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;

    @media only screen and (max-width: 768px) {
        margin-top: 24px;
    }
`;
