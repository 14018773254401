import React, { useState } from 'react';
import { FormContainer } from './whitepaperForm.style';
import WhitepaperForm from '../../../../components/reusable/forms/Whitepaper';

const FinanceWhitepaperForm = () => {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    return (
        <FormContainer>
            <WhitepaperForm
                formId="6407338c-949c-4563-9415-532566373416"
                isFormSubmitted={isFormSubmitted}
                setIsFormSubmitted={setIsFormSubmitted}
            />
        </FormContainer>
    );
};

export default FinanceWhitepaperForm;
