import styled from 'styled-components';
import { breakpoints } from '../../../../styles/screenSizes';

export const Wrapper = styled.section`
    margin-top: 102px;

    @media only screen and (max-width: ${breakpoints.sm}) {
        margin-top: 60px;
    }
`;

export const Container = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 0px 24px;

    @media only screen and (max-width: ${breakpoints.sm}) {
        padding: 0px 16px;
    }
`;

export const HeadingContainer = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 6px;
    margin-bottom: 64px;

    @media only screen and (max-width: ${breakpoints.md}) {
        align-items: center;
        flex-direction: column;
        margin-bottom: 44px;
    }
`;

export const HeadingText = styled.h1`
    font-size: 42px;
    color: #262626;
    font-weight: 500;
    line-height: 50.4px;

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: 30px;
        line-height: 36px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        font-size: 24px;
        line-height: 28.8px;
    }
`;

export const Logo = styled.img`
    height: 32px;
    width: auto;
    margin-bottom: 10px;

    @media only screen and (max-width: ${breakpoints.md}) {
        height: 24px;
        margin-bottom: 0px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        height: 20.91px;
    }
`;
