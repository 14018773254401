import styled, { css } from 'styled-components';
import { breakpoints } from '../../../../styles/screenSizes';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 0.6fr 1fr;

    @media only screen and (max-width: ${breakpoints.md}) {
        gap: 26px;
    }
`;

export const RightDiv = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
`;

export const Img = styled.img`
    position: absolute;
    transition: all 0.3s ease;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    height: 90%;
    width: auto;

    @media only screen and (max-width: ${breakpoints.md}) {
        height: auto;
        width: 100%;
        object-fit: contain;
    }
`;

export const LeftDiv = styled.div`
    width: 100%;

    > div:nth-child(n + 1) {
        padding: 14px 0px;
    }

    @media only screen and (max-width: ${breakpoints.md}) {
        > div:nth-child(n + 1) {
            padding: 10px 0px;
        }
    }
`;

export const Box = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
`;

export const Div = styled.div`
    display: flex;
    gap: 16px;
`;

export const IconContainer = styled.div`
    height: 32px;
    width: 32px;
    border-radius: 4px;
    border: 1px solid #eaecf0;
    flex-shrink: 0;
    display: grid;
    place-items: center;

    > img {
        filter: invert(13%) sepia(51%) saturate(441%) hue-rotate(174deg) brightness(91%)
            contrast(94%);
    }

    @media only screen and (max-width: ${breakpoints.md}) {
        height: 20px;
        width: 20px;
        border-radius: 2.25px;
        border: 0.63px solid #eaecf0;

        > img {
            height: 15px;
            width: 15px;
        }
    }
`;

export const Contents = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Heading = styled.h1`
    font-size: ${({ isActive }) => (isActive ? '24px' : '20px')};
    line-height: 31.2px;
    font-weight: ${({ isActive }) => (isActive ? 500 : 400)};
    color: ${({ isActive }) => (isActive ? '#1d2939' : '#667085')};

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: ${({ isActive }) => (isActive ? '14px' : '12px')};
        line-height: 18.2px;
    }
`;

export const Desc = styled.p`
    padding-top: 20px;
    font-size: 16px;
    line-height: 22.4px;
    color: #1d2939;

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: 12px;
        line-height: 16.8px;
        padding-top: 12px;
    }
`;

export const Pointers = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    margin-top: 24px;

    > div {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    @media only screen and (max-width: ${breakpoints.md}) {
        margin-top: 12px;
        gap: 8px;
    }
`;

export const Bullet = styled.div`
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #e0f2fe;
    flex-shrink: 0;
    display: grid;
    place-items: center;

    > img {
        filter: invert(42%) sepia(99%) saturate(3085%) hue-rotate(175deg) brightness(88%)
            contrast(101%);
    }

    @media only screen and (max-width: ${breakpoints.md}) {
        height: 16px;
        width: 16px;
    }
`;

export const Text = styled.p`
    color: #475467;
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: 12px;
        line-height: 16.8px;
    }
`;

const SharedCtaStyle = css`
    margin-top: 26px;
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 8px;
    width: max-content;

    > p {
        font-family: 'Rubik', sans-serif;
        color: #154683;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        cursor: pointer;
    }

    img {
        filter: invert(21%) sepia(96%) saturate(817%) hue-rotate(185deg) brightness(93%)
            contrast(99%);
    }

    @media only screen and (max-width: ${breakpoints.md}) {
        margin-top: 18px;

        p {
            font-size: 12px;
            line-height: 20px;
        }

        img {
            height: 18px;
            width: 18px;
        }
    }
`;

export const ContactCta = styled.button`
    ${SharedCtaStyle}
    background-color: transparent;
    outline: none;
    border: none;
`;

export const Cta = styled.a`
    ${SharedCtaStyle}
`;

export const ProgressBar = styled.div`
    position: relative;
    height: 2px;
    width: 100%;
    background-color: #eaecf0;
    border-radius: 10px;
    margin-top: 14px;
    overflow: hidden;

    > div {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #1570ef;
        transform-origin: left;
        transform: scaleX(0);
        border-radius: 10px;
    }
`;

export const HiddenContents = styled.div`
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    height: ${({ height }) => (height ? `${height}px` : '0px')};
`;
