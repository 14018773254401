import React from 'react';

import Bot from '../../images/Icons/icon-bot.svg';
import BotFilled from '../../images/Icons/icon-bot-filled.svg';
import Paper from '../../images/Icons/icon-paper-with-graph.svg';
import PaperFilled from '../../images/Icons/icon-paper-with-graph-filled.svg';
import UserFrame from '../../images/Icons/icon-user-frame.svg';
import UserFrameFilled from '../../images/Icons/icon-user-frame-filled.svg';
import Stats from '../../images/Icons/icon-graph-stats.svg';

export const FRAUDS = [
    {
        id: 1,
        title: (
            <>
                Over <b>2 Million</b> Fraud Reports
            </>
        ),
        icon: Bot,
        hoverIcon: BotFilled,
        iconColor:
            'invert(26%) sepia(90%) saturate(6874%) hue-rotate(189deg) brightness(92%) contrast(98%)',
        bgColor: '#E0F2FE',
    },
    {
        id: 2,
        title: (
            <>
                Screen from <b>Global Watchlists</b>
            </>
        ),
        icon: Paper,
        hoverIcon: PaperFilled,
        iconColor:
            'invert(26%) sepia(58%) saturate(2393%) hue-rotate(243deg) brightness(89%) contrast(91%)',
        bgColor: '#E9D7FE',
    },
    {
        id: 3,
        title: (
            <>
                <b>41% increase</b> in fraud incidents
            </>
        ),
        icon: UserFrame,
        hoverIcon: UserFrameFilled,
        iconColor:
            'invert(26%) sepia(75%) saturate(3100%) hue-rotate(348deg) brightness(86%) contrast(97%)',
        bgColor: '#FECDCA',
    },
    {
        id: 4,
        title: (
            <>
                25% of financial organizations reported <b>$1M in fraud losses</b>
            </>
        ),
        icon: Stats,
        hoverIcon: Stats,
        iconColor:
            'invert(44%) sepia(32%) saturate(4986%) hue-rotate(10deg) brightness(96%) contrast(98%)',
        bgColor: '#FEF0C7 ',
    },
];
