import styled from 'styled-components';
import { breakpoints } from '../../../../styles/screenSizes';

export const Container = styled.div``;

export const Contents = styled.div`
    margin-top: 54px;

    @media only screen and (max-width: ${breakpoints.lg}) {
        margin-top: 30px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        margin-top: 16px;
    }
`;

export const Para = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #262626;

    @media only screen and (max-width: ${breakpoints.sm}) {
        font-size: 14px;
        line-height: 21px;
    }
`;

export const DataContainer = styled.div`
    margin: 24px 0px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @media only screen and (max-width: ${breakpoints.md}) {
        grid-template-columns: 1fr;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        grid-template-columns: 1fr;
        margin: 12px 0px 20px 0px;
    }
`;

export const Data = styled.div`
    border: 1px solid #dddddd;
    border-radius: 12px;
    padding: 16px 14px;
    display: flex;
    align-items: center;
    gap: 12px;

    @media only screen and (max-width: ${breakpoints.sm}) {
        padding: 12px;
    }
`;

export const IconContainer = styled.div`
    height: 32px;
    width: 32px;
    border-radius: 5.82px;
    display: grid;
    place-items: center;
    flex-shrink: 0;
    background-color: ${({ bgcolor }) => bgcolor};

    img {
        filter: ${({ iconcolor }) => iconcolor};
    }
`;

export const Text = styled.p`
    font-size: 14px;
    line-height: 17.5px;
    color: #1d2939;
    font-weight: 400;

    b {
        font-weight: 500;
    }
`;
