import React from 'react';
import {
    Wrapper,
    Heading,
    PageTitle,
    Contents,
    FormContainer,
    Container,
} from './heroSection.style';
import FinanceWhitepaperForm from '../../components/WhitepaperForm';
import { useMediaQuery } from '@mui/material';
import Highlights from '../../components/Highlights';

const HeroSection = () => {
    const isSmallScreen = useMediaQuery('(max-width: 768px)');
    return (
        <Wrapper>
            <Container>
                <Contents>
                    <PageTitle>WHITEPAPER • FINANCE</PageTitle>
                    <Heading>Impact of Fraud on Financial Institutions</Heading>
                    {isSmallScreen ? (
                        <FormContainer>
                            <FinanceWhitepaperForm />
                        </FormContainer>
                    ) : (
                        <Highlights />
                    )}
                </Contents>

                {isSmallScreen ? (
                    <Highlights />
                ) : (
                    <FormContainer>
                        <FinanceWhitepaperForm />
                    </FormContainer>
                )}
            </Container>
        </Wrapper>
    );
};

export default HeroSection;
