import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { formatText } from '../../../utils';

const Card = styled.a`
    text-decoration: none;
    border: 1px solid #eaecf0;
    border-radius: 24px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    @media not all and (max-width: 820px) {
        &:hover {
            background: rgba(245, 250, 255, 1);
            border: 1px solid #1849a9;
            stroke: #1849a9;
        }
    }

    @media only screen and (max-width: 820px) {
        border-radius: 16px;
        transition: none;
        padding: 16px;
        gap: 24px;
    }
`;
const HeadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 14px;
`;
const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > img {
        display: none;
        transition: all 0.2s ease-in-out;
    }

    ${Card}:hover & {
        > img {
            display: block;

            @media only screen and (max-width: 820px) {
                display: none;
            }
        }
    }
`;
const Title = styled.h3`
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #667085;
    text-transform: uppercase;

    @media only screen and (max-width: 820px) {
        font-size: 12px;
        line-height: 14.22px;
    }
`;
const Heading = styled.h1`
    font-size: 24px;
    font-weight: 500;
    line-height: 28.44px;
    color: #1d2939;

    @media only screen and (max-width: 820px) {
        font-size: 16px;
        line-height: 18.96px;
    }
`;

const Contents = styled.div`
    display: flex;
    flex-direction: column;
    gap: 44px;

    @media only screen and (max-width: 560px) {
        gap: 24px;
    }
`;

const MeasuresBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
`;

const DescriptionContainer = styled.div`
    color: #475467;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.96px;

    @media only screen and (max-width: 560px) {
        font-size: 12px;
        line-height: 14.22px;
    }
`;
const DescriptionHeading = styled.span`
    font-weight: 600;
    color: #475467;
`;

const Logo = styled.img`
    height: 45px;
    width: auto;

    @media only screen and (max-width: 820px) {
        height: 24px;
    }
`;

const ComplianceMeasuresCard = ({ data }) => {
    return (
        <Card target="_blank" rel="noreferrer" href="https://info.authenticate.com/compliance">
            <HeadingContainer>
                <TitleContainer>
                    <Title>{data.title}</Title>
                    <img
                        src={data.icon}
                        alt="check mark"
                        draggable="false"
                        width={20}
                        height={20}
                    />
                </TitleContainer>
                <Heading>{data.heading}</Heading>
            </HeadingContainer>
            <Contents>
                {data?.items?.map((item, index) => (
                    <MeasuresBox key={`${item.heading}-${index}`}>
                        <Logo src={item.logo} alt={`${item.heading}_logo`} />
                        <DescriptionContainer>
                            <DescriptionHeading>{item.heading}:</DescriptionHeading>{' '}
                            {formatText(item.description)}
                        </DescriptionContainer>
                    </MeasuresBox>
                ))}
            </Contents>
        </Card>
    );
};

ComplianceMeasuresCard.propTypes = {
    data: PropTypes.object,
};

export default ComplianceMeasuresCard;
