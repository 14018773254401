import React from 'react';
import RootLayout from '../../layouts/RootLayout';
import HeroSection from '../../sections/Whitepaper/Finance/HeroSection';
import FraudPreventionBanner from '../../sections/Whitepaper/Finance/FraudPreventionBanner';
import MetaTagsProvider from '../../components/MetaTagsProvider';

const Whitepaper = () => {
    return (
        <RootLayout getStartedCtaLink="https://portal.authenticate.com/register?utm_source=industry&utm_medium=finance&utm_campaign=lander&utm_term=navbar">
            <MetaTagsProvider
                title="Authenticate: Impact of Fraud in Financial Institutions"
                description="Learn how Financial Institutions leverage KYC to prevent fraud. Authenticate's research reveals the impact of financial fraud and provides cutting-edge solutions like AI-driven ID verification, global watchlist checks, and background checks to combat evolving threats."
                keywords="Authenticate, financial fraud report, identity verification, AI-driven KYC, AML compliance, continuous monitoring, fraud prevention, account takeover, financial institutions, regulatory compliance, global watchlist checks, criminal checks, financial security solutions"
                path="whitepapers/finance"
                ogTitle="Authenticate: Impact of Fraud in Financial Institutions"
                ogDescription="Discover the true scope of financial fraud with Authenticate’s research report. Learn how advanced solutions like AI-driven ID checks and continuous monitoring can protect financial institutions against modern fraudsters."
                twitterTitle="Authenticate: Combat Financial Fraud with Advanced Solutions"
                twitterDescription="Financial fraud is on the rise—41% increase in bank fraud and a 733% surge in P2P fraud. Discover how Authenticate helps financial institutions prevent fraud with cutting-edge tech."
                ogImage="https://cdn.authenticating.com/public/website/industries/finance/financial_fraud_research_report.png"
            />
            <HeroSection />
            <FraudPreventionBanner />
        </RootLayout>
    );
};

export default Whitepaper;
