import styled, { css } from 'styled-components';

export const Card = styled.div`
    padding: 20px 0px;

    &:nth-child(2) {
        border-top: 1px solid #f2f4f7;
        border-bottom: 1px solid #f2f4f7;
    }
`;

export const StepImageContainer = styled.div`
    width: 100%;
    height: auto;
    > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;

export const Content = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 8px;
    margin-top: 20px;
`;

export const IconContainer = styled.div`
    height: 20px;
    width: 20px;
    border: 2.5px;
    border: 1px solid #eaecf0;
    display: grid;
    place-items: center;

    > img {
        width: 15px;
        height: 15px;
        filter: invert(13%) sepia(51%) saturate(441%) hue-rotate(174deg) brightness(91%)
            contrast(94%);
    }
`;

export const Details = styled.div``;

export const Heading = styled.h3`
    font-size: 14px;
    line-height: 18.2px;
    color: #1d2939;
    font-weight: 500;
`;

export const Para = styled.p`
    font-size: 12px;
    line-height: 16.8px;
    color: #1d2939;
    font-weight: 400;
    margin: 12px 0px;
`;

export const Points = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    &:nth-child(n + 1) {
        margin-top: 8px;
    }
`;

export const Bullet = styled.div`
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: #e0f2fe;
    display: grid;
    place-items: center;
    flex-shrink: 0;

    > img {
        filter: invert(42%) sepia(99%) saturate(3085%) hue-rotate(175deg) brightness(88%)
            contrast(101%);
    }
`;

export const Text = styled.p`
    font-size: 12px;
    line-height: 16.8px;
    font-weight: 400;
    color: #475467;
`;

const SharedCtaStyle = css`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 18px;
    background-color: transparent;
    outline: none;
    border: none;
    text-decoration: none;

    > img {
        filter: invert(21%) sepia(96%) saturate(817%) hue-rotate(185deg) brightness(93%)
            contrast(99%);
        height: 16px;
        width: 16px;
    }
`;

export const ContactCta = styled.button`
    ${SharedCtaStyle}
`;

export const Cta = styled.a`
    ${SharedCtaStyle}
`;

export const CtaLabel = styled.p`
    font-family: 'Rubik', sans-serif;
    font-size: 12px;
    line-height: 18px;
    color: #154683;
    font-weight: 500;
    text-decoration: none;
`;
