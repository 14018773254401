import { Hidden } from '@mui/material';
import React from 'react';
import VerificationsMobile from './VerificationsMobile';
import VerificationsDesktop from './VerificationsDesktop';

const Verifications = () => {
    return (
        <>
            <Hidden xsUp>
                <VerificationsMobile />
            </Hidden>
            <Hidden smDown>
                <VerificationsDesktop />
            </Hidden>
        </>
    );
};

export default Verifications;
