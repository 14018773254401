import React, { useEffect, useRef } from 'react';
import { CardsContainer, Container, Data, Heading, Logo, Wrapper } from './fraudPrevention.style';
import FraudPreventionCard from '../../components/FraudPreventionCard';
import { ID_FRAUD_PREVENTIONS } from '../../utilities';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import Authenticate_Logo from '../../../../images/logos/authenticate_light.svg';

gsap.registerPlugin(ScrollTrigger);

const FraudPrevention = () => {
    const headingRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        const timeline = gsap.timeline({
            scrollTrigger: {
                trigger: headingRef?.current,
                start: 'top 80%',
            },
        });

        timeline
            .fromTo(
                containerRef?.current,
                { y: 60, opacity: 0 },
                { y: 0, opacity: 1, duration: 0.4, ease: 'none' }
            )
            .fromTo(
                headingRef?.current,
                { y: 60, opacity: 0 },
                { y: 0, opacity: 1, duration: 0.4, ease: 'none' },
                '-=0.2' // Overlap the second animation slightly
            );

        // Cleanup function
        return () => {
            timeline.kill(); // Kill the timeline and associated ScrollTrigger
            ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
        };
    }, []);

    return (
        <Wrapper>
            <Container>
                <Data ref={containerRef}>
                    <Heading ref={headingRef}>
                        Seamless Fraud Prevention with{' '}
                        <Logo src={Authenticate_Logo} alt="Authenticate Logo" />
                    </Heading>
                    <CardsContainer>
                        <FraudPreventionCard data={ID_FRAUD_PREVENTIONS.DATA_1} />
                        <FraudPreventionCard data={ID_FRAUD_PREVENTIONS.DATA_2} secondary />
                    </CardsContainer>
                </Data>
            </Container>
        </Wrapper>
    );
};

export default FraudPrevention;
