import styled from 'styled-components';
import { breakpoints } from '../../../../styles/screenSizes';

export const FormContainer = styled.div`
    max-width: 460px;
    padding: 44px;
    position: relative;
    z-index: 1;
    border-radius: 12px;
    border: 1px solid;
    background: linear-gradient(#fff, #fff) padding-box,
        linear-gradient(0deg, #2a8af0 -8.94%, #ffffff 126.81%) border-box;
    border: 1px solid transparent;
    overflow: hidden;
    height: fit-content;

    @media only screen and (max-width: ${breakpoints.md}) {
        max-width: 328px;
        padding: 24px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        max-width: 100%;
    }
`;
