import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './style.css';
import Loader from '../../../../sections/Resources/components/Loader';
import { whitepaperApi } from '../../../../api';
import FinanceWhitepaperResponse from '../../../../sections/Whitepaper/components/FinanceWhitepaperResponse';
import { FormHeading } from './whitepaper.style';

const WhitepaperForm = ({ formId, isFormSubmitted, setIsFormSubmitted }) => {
    const [loading, setLoading] = useState(true);
    const [apiResponse, setApiResponse] = useState(null);
    const [error, setError] = useState(null);

    const loadFormScript = () => {
        window.hbspt.forms.create({
            region: 'na1',
            portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
            formId,
            target: '#whitepaperForm',
            onFormReady: () => {
                setLoading(false); // Hide loader when the form is ready
            },
            onFormSubmit: async () => {
                setLoading(true); // Show loader during submission
                setError(null);
                setIsFormSubmitted(false);

                const form = document.querySelector('#whitepaperForm form');
                if (form) {
                    const formElements = form.elements;
                    const formData = {};

                    for (let element of formElements) {
                        if (element.name) {
                            formData[element.name] = element.value;
                        }
                    }

                    if (formData.email) {
                        try {
                            const response = await whitepaperApi(formData.email);
                            setApiResponse(response);
                            setIsFormSubmitted(true);
                        } catch (err) {
                            setError(err);
                        } finally {
                            setLoading(false);
                        }
                    } else {
                        setLoading(false);
                    }
                }

                return false;
            },
        });
    };

    const addFormScriptToPage = () => {
        const existingScript = document.getElementById('hubspot-form-script');
        if (!existingScript) {
            const script = document.createElement('script');
            script.src = 'https://js.hsforms.net/forms/embed/v2.js';
            script.async = true;
            script.onload = loadFormScript;
            script.id = 'hubspot-form-script';
            document.body.appendChild(script);
        } else {
            loadFormScript();
        }
    };

    useEffect(() => {
        addFormScriptToPage();
        return () => {
            const formContainer = document.getElementById(`whitepaperForm-${formId}`);
            if (formContainer) {
                formContainer.innerHTML = ''; // Cleanup on unmount
            }
        };
    }, [formId]);

    // Apply styles to hide/show the form based on `loading` state
    useEffect(() => {
        const formContainer = document.getElementById('whitepaperForm');
        if (formContainer) {
            formContainer.style.display = loading ? 'none' : 'block';
        }
    }, [loading]);

    return (
        <>
            {loading && <Loader />}
            {(!loading && isFormSubmitted && apiResponse) || error ? (
                <FinanceWhitepaperResponse data={apiResponse} error={error} />
            ) : (
                <>
                    <FormHeading>Access the Whitepaper</FormHeading>
                    <div id="whitepaperForm" />
                </>
            )}
        </>
    );
};

WhitepaperForm.propTypes = {
    formId: PropTypes.string,
    isFormSubmitted: PropTypes.bool,
    setIsFormSubmitted: PropTypes.func,
};

export default WhitepaperForm;
