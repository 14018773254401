import React, { useState } from 'react';
import {
    Container,
    Contents,
    Data,
    DataContainer,
    IconContainer,
    Para,
    Text,
} from './highlights.style';
import { FRAUDS } from '../../utils';

const Highlights = () => {
    const [hoverItem, setHoverItem] = useState(null);

    return (
        <Container>
            <Contents>
                <Para>
                    According to the latest data from the Federal Trade Commission, reported
                    consumer losses to fraud exceeded $10 billion in 2023—a historic high for
                    fraud-related losses.
                </Para>
                <DataContainer>
                    {FRAUDS.map((data) => (
                        <Data
                            key={data.id}
                            onMouseEnter={() => setHoverItem(data.id)}
                            onMouseLeave={() => setHoverItem(null)}
                        >
                            <IconContainer bgcolor={data.bgColor} iconcolor={data.iconColor}>
                                <img
                                    src={hoverItem === data.id ? data.hoverIcon : data.icon}
                                    alt="icon"
                                />
                            </IconContainer>
                            <Text>{data.title}</Text>
                        </Data>
                    ))}
                </DataContainer>
                <Para>
                    Download our comprehensive report to learn more about how fraud is affecting
                    financial institutions and discover strategies for effective fraud mitigation.
                </Para>
            </Contents>
        </Container>
    );
};

export default Highlights;
