export const whitepaperStates = {
    downloadReady: {
        title: 'Access the Whitepaper',
        desc: 'Your copy of Research Report on the Impact of Fraud on Financial Institutions is ready.',
        download: true,
    },
    spamEmail: {
        title: 'Access the Whitepaper',
        desc: 'Thank you for requesting the report! It will arrive in your inbox shortly.',
    },
    rateLimitedApi: {
        title: 'Access the Whitepaper',
        desc: 'Thank you for requesting the report! It will arrive in your inbox shortly.',
    },
    downloadComplete: {
        title: 'Thank you!',
        desc: 'Your whitepaper has been successfully downloaded.',
    },
};
