import styled from 'styled-components';
import { breakpoints } from '../../../../styles/screenSizes';

export const Container = styled.div`
    text-align: center;
`;
export const ImgContainer = styled.div`
    width: 290px;
    height: 384px;
    border: 1px solid #dddddd;
    border-radius: 8px;
    overflow: hidden;
    margin: auto;

    @media only screen and (max-width: ${breakpoints.sm}) {
        width: 214px;
        height: 283px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;
export const Text = styled.p`
    font-size: 16px;
    line-height: 18.96px;
    color: #344054;
    margin-top: 30px;

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: 14px;
    }
`;

export const DownloadButton = styled.button`
    all: unset;
    padding: 8px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    margin-top: 40px;
    text-decoration: none;
    background-color: #0c1a68;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    color: #fff;
    font-family: Rubik, sans-serif;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;

    &:hover {
        transform: scale(1.02);
    }

    img {
        filter: invert(1);
        height: 18px;
        width: 18px;
    }
`;
