import React, { useEffect, useRef } from 'react';
import {
    Container,
    FraudsContainer,
    HeadingContainer,
    MainHeading,
    Text,
    WhitePaperCta,
    Wrapper,
} from './identityFrauds.style';
import ArrowRight from '../../../../images/Icons/at_chevron-right.svg';
import IdFrauds from '../../components/IdFrauds';
import { ID_FRAUDS } from '../../utilities';
import { useMediaQuery } from '@mui/material';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);

const IdentityFrauds = () => {
    const headingRef = useRef(null);
    const ctaRef = useRef(null);

    useEffect(() => {
        const timeline = gsap.timeline({
            scrollTrigger: {
                trigger: headingRef?.current,
                start: 'top 80%',
            },
        });

        timeline
            .fromTo(
                headingRef?.current,
                { y: 60, opacity: 0 },
                { y: 0, opacity: 1, ease: 'none', duration: 0.4 }
            )
            .fromTo(
                ctaRef?.current,
                { y: 60, opacity: 0 },
                { y: 0, opacity: 1, ease: 'none', duration: 0.4 },
                '-=0.3' // Slight overlap with the previous animation
            );

        // Cleanup function
        return () => {
            timeline?.kill(); // Clean up the timeline and associated ScrollTrigger
            ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
        };
    }, []);

    const isMobile = useMediaQuery('(max-width: 600px)');

    const textTransform = isMobile ? <>&mdash; </> : <br />;

    return (
        <Wrapper>
            <Container>
                <HeadingContainer>
                    <MainHeading ref={headingRef}>
                        Identity Fraud Costs Financial Institutions $6 Billion {textTransform}
                        <span>Are You Protected?</span>
                    </MainHeading>
                    <WhitePaperCta to="/whitepapers/finance" ref={ctaRef}>
                        <Text>Get Whitepaper</Text>
                        <img src={ArrowRight} alt="arrow-right" />
                    </WhitePaperCta>
                </HeadingContainer>
                <FraudsContainer>
                    <IdFrauds data={ID_FRAUDS.DATA_1} />
                    <IdFrauds data={ID_FRAUDS.DATA_2} reverse />
                </FraudsContainer>
            </Container>
        </Wrapper>
    );
};

export default IdentityFrauds;
