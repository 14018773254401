import { Link } from 'gatsby';
import styled from 'styled-components';
import { breakpoints } from '../../../../styles/screenSizes';

export const Wrapper = styled.section`
    margin-top: 102px;

    @media only screen and (max-width: ${breakpoints.md}) {
        margin-top: 72px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        margin-top: 54px;
    }
`;

export const Container = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 24px;
`;

export const HeadingContainer = styled.div`
    text-align: center;
    overflow: hidden;
`;

export const MainHeading = styled.h1`
    font-size: 42px;
    line-height: 50.4px;
    font-weight: 500;
    color: #262626;
    max-width: 90%;
    margin: auto;

    > span {
        color: #4795c4;

        @media only screen and (min-width: ${breakpoints.sm}) {
            font-style: italic;
        }
    }

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: 30px;
        line-height: 36px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        font-size: 24px;
        line-height: 28.8px;
        max-width: 100%;
    }
`;

export const WhitePaperCta = styled(Link)`
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: max-content;
    margin: 24px auto 0 auto;
    padding: 8px 16px;
    border-radius: 4px;
    background-color: #154683;
    cursor: pointer;

    > img {
        filter: invert(1);
        height: 20px;
        width: 20px;
        transition: transform 0.2s ease-in-out;
    }

    &:hover > img {
        transform: translateX(3px);
    }
`;

export const Text = styled.p`
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
`;

export const FraudsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 54px;
    gap: 40px;
`;
