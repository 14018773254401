import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { formatText } from '../../utils';

gsap.registerPlugin(ScrollTrigger);

const Container = styled.div``;

const ResourceContainer = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 84px 24px 0 30px;

    @media screen and (max-width: 744px) {
        padding: 54px 16px 0 16px;
    }
`;

const Heading = styled.h1`
    font-size: 42px;
    font-weight: 500;
    line-height: 48px;
    text-align: center;
    color: #262626;

    @media screen and (max-width: 744px) {
        font-size: 32px;
        line-height: 36px;
    }

    @media screen and (max-width: 520px) {
        font-size: 24px;
        line-height: 28.44px;
    }
`;

const ResourceGrid = styled.div`
    margin-top: 54px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 44px;

    @media screen and (max-width: 850px) {
        gap: 20px;
        margin-top: 24px;
    }
`;

const Card = styled.a`
    border: 1px solid #e8e8e8;
    border-radius: 16px;
    padding: 16px;
    text-decoration: none;
    cursor: pointer;
    background-color: #fff;

    @media only screen and (max-width: 850px) {
        padding: 12px;
    }
`;

const CardHeader = styled.div`
    height: 96px;
    width: 100%;
    border: 1px solid #ececec;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    background-color: ${({ cardType }) => (cardType === 'product' ? '#154997' : '#fff')};
`;

const CardBanner = styled.img`
    height: 100%;
    width: auto;
    object-fit: cover;

    ${({ cardType, page }) => {
        if (cardType === 'blog') {
            return css`
                height: 100%;
                width: 100%;
                object-position: ${page === 'KYC/AML' ? '0 -20px' : 'center'};
                object-fit: cover;
            `;
        }

        if (cardType === 'product') {
            return css`
                margin-top: 12px;
                border: 2.18px solid #ffffff;
                border-radius: 4px;
            `;
        }

        if (cardType === 'solutions') {
            return css`
                width: 100%;
                object-fit: cover;
            `;
        }
    }}
`;

const ProductTag = styled.div`
    margin-top: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 2px 10px;
    border-radius: 16px;
    background: ${({ tagBackground }) => tagBackground};
    color: ${({ tagColor }) => tagColor};
    display: inline-block;

    @media only screen and (max-width: 850px) {
        font-size: 12px;
        line-height: 18px;
        padding: 2px 8px;
    }
`;

const CardHeading = styled.h3`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #262626;
    margin-top: 4px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    @media only screen and (max-width: 850px) {
        -webkit-line-clamp: 2;
        font-size: 14px;
        line-height: 18.96px;
    }
`;

const CardDesc = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 16.59px;
    color: #4b5563;
    margin-top: 4px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;

    @media only screen and (max-width: 850px) {
        -webkit-line-clamp: 3;
        font-size: 12px;
        line-height: 14.22px;
    }
`;

function Resources({ item }) {
    useEffect(() => {
        gsap.fromTo(
            '.resources-container',
            { opacity: 0, y: 30 },
            {
                opacity: 1,
                y: 0,
                duration: 0.3,
                ease: 'none',
                scrollTrigger: { trigger: '.resources-container', start: 'top 80%' },
            }
        );

        return () => {
            ScrollTrigger.killAll();
        };
    }, []);
    return (
        <Container>
            <ResourceContainer className="resources-container">
                <Heading>Resources</Heading>
                <ResourceGrid>
                    {item?.map((data) => (
                        <Card href={data.link} target="_blank" rel="noreferrer" key={data.id}>
                            <CardHeader cardType={data.cardType}>
                                <CardBanner
                                    src={data.banner}
                                    alt="Blogs"
                                    cardType={data.cardType}
                                />
                            </CardHeader>

                            <ProductTag
                                tagColor={data.tagColor}
                                tagBackground={data.tagBackgroundColor}
                            >
                                {data.tag}
                            </ProductTag>
                            <CardHeading>{formatText(data.title)}</CardHeading>
                            <CardDesc>{formatText(data.description)}</CardDesc>
                        </Card>
                    ))}
                </ResourceGrid>
            </ResourceContainer>
        </Container>
    );
}

Resources.propTypes = {
    item: PropTypes.array,
};

export default Resources;
