import React, { useEffect, useRef, useState } from 'react';
import { AUTHENTICATION_STEPS } from '../../utilities';
import {
    Container,
    HeadingContainer,
    HeadingText,
    Logo,
    Wrapper,
} from './authenticationSteps.style';
import ProgressBarCarousal from '../../components/ProgressBarCarousal';
import AuthenticateLogo from '../../../../images/logos/authenticate_rgb.svg';
import { useMediaQuery } from '@mui/material';
import ResponsiveAuthenticationSteps from '../ResponsiveAuthenticationSteps';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);

const AuthenticationSteps = () => {
    const isSmallScreen = useMediaQuery('(max-width: 600px)');
    const [hydrated, setHydrated] = useState(false);
    const headingContainerRef = useRef(null);

    useEffect(() => {
        setHydrated(true);
    }, []);

    useEffect(() => {
        if (headingContainerRef.current) {
            const animation = gsap.fromTo(
                headingContainerRef.current,
                { y: 60, opacity: 0 },
                {
                    y: 0,
                    opacity: 1,
                    ease: 'none',
                    duration: 0.2,
                    scrollTrigger: {
                        trigger: headingContainerRef.current,
                        start: 'top 80%',
                    },
                }
            );

            return () => {
                animation?.kill();
                ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
            };
        }
    }, []);

    if (!hydrated) {
        return null;
    }
    return (
        <Wrapper>
            <Container>
                <HeadingContainer ref={headingContainerRef}>
                    <HeadingText>How Financial Institutions</HeadingText>
                    <Logo src={AuthenticateLogo} alt="Authenticate Logo" />
                </HeadingContainer>
                {isSmallScreen ? (
                    <ResponsiveAuthenticationSteps items={AUTHENTICATION_STEPS} />
                ) : (
                    <ProgressBarCarousal items={AUTHENTICATION_STEPS} />
                )}
            </Container>
        </Wrapper>
    );
};

export default AuthenticationSteps;
