import styled from 'styled-components';
import { breakpoints } from '../../../../styles/screenSizes';

export const FormHeading = styled.h1`
    color: #1d2939;
    font-weight: 500;
    font-size: 24px;
    line-height: 31.2px;
    margin-bottom: 30px;
    text-align: center;

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: 20px;
        line-height: 26px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        text-align: center;
    }
`;
