import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
    Bullet,
    Card,
    ContactCta,
    Cta,
    Heading,
    ImgContainer,
    Points,
    SubText,
    Text,
} from './fraudPreventionCard.style';
import CheckMarkIcon from '../../../../images/Icons/icon-checkmark-styled.svg';
import { useModal } from '../../../../context/ModalContext';
import gsap from 'gsap';

const FraudPreventionCard = ({ data, secondary }) => {
    const { openModal } = useModal();
    const cardRef = useRef(null);

    useEffect(() => {
        if (cardRef.current) {
            const animation = gsap.fromTo(
                cardRef.current,
                { x: secondary ? 40 : -40, opacity: 0 },
                {
                    x: 0,
                    opacity: 1,
                    ease: 'none',
                    duration: 0.3,
                    scrollTrigger: {
                        trigger: cardRef?.current,
                        start: 'top 50%',
                    },
                }
            );

            return () => animation.kill();
        }
    }, []);
    return (
        <Card ref={cardRef}>
            <Heading>{data.heading}</Heading>
            <SubText>{data.subText}</SubText>
            <ImgContainer>
                <img src={data.img} alt="graphic" />
            </ImgContainer>
            <Points>
                {data?.points.map((point, index) => (
                    <div key={point?.substring(0, 10) + index}>
                        <Bullet>
                            <img src={CheckMarkIcon} alt="checkmark-icon" />
                        </Bullet>
                        <Text>{point}</Text>
                    </div>
                ))}
            </Points>
            {data?.cta?.isPopup ? (
                <ContactCta type="button" onClick={() => openModal('contact')}>
                    {data.cta.text}
                </ContactCta>
            ) : (
                <Cta to={data?.cta?.link}>{data?.cta.text}</Cta>
            )}
        </Card>
    );
};

FraudPreventionCard.propTypes = {
    data: PropTypes.object,
    secondary: PropTypes.bool,
};

export default FraudPreventionCard;
