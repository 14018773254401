import styled, { css } from 'styled-components';
import { breakpoints } from '../../../../styles/screenSizes';
import { Link } from 'gatsby';

export const Card = styled.div`
    background: linear-gradient(156.05deg, #ffffff 46.59%, #ffffff 108.44%);
    border-radius: 20px;
    box-shadow: 0px 4px 16px -4px #10182814;
    padding: 44px;
    text-align: center;
    width: 100%;

    @media only screen and (max-width: ${breakpoints.md}) {
        border-radius: 8px;
        padding: 16px 12px;
    }
`;

export const Heading = styled.h2`
    font-size: 32px;
    line-height: 44.8px;
    color: #344054;
    font-weight: 500;

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: 18px;
        line-height: 25.2px;
        max-width: 75%;
        margin: auto;
    }
`;

export const SubText = styled.p`
    font-size: 16px;
    line-height: 24px;
    color: #667085;
    margin-top: 16px;

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: 12px;
        line-height: 18px;
    }
`;

export const ImgContainer = styled.div`
    margin-top: 16px;

    > img {
        height: auto;
        width: 100%;
        object-fit: cover;
    }
`;

export const Points = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 32px;

    > div {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    @media only screen and (max-width: ${breakpoints.md}) {
        margin-top: 16px;
        gap: 8px;
    }
`;

export const Bullet = styled.div`
    height: 20px;
    width: 20px;
    background-color: #e0f2fe;
    border-radius: 50%;
    display: grid;
    place-items: center;

    > img {
        filter: invert(42%) sepia(99%) saturate(3085%) hue-rotate(175deg) brightness(88%)
            contrast(101%);
    }
`;

export const Text = styled.p`
    font-size: 16px;
    line-height: 24px;
    color: #667085;
    text-align: left;

    @media only screen and (max-width: ${breakpoints.md}) {
        font-size: 12px;
        line-height: 18px;
    }
`;
const CommonCtaStyle = css`
    display: block;
    margin-top: 40px;
    width: 100%;
    border: 1px solid #154683;
    box-shadow: 0px 1px 2px 0px #1018280d;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 0;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.05);
    }

    @media only screen and (max-width: ${breakpoints.md}) {
        margin-top: 24px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        margin-top: 18px;
        font-size: 12px;
        padding: 8px 0;
    }
`;

export const ContactCta = styled.button`
    ${CommonCtaStyle}
    font-family: 'Rubik', sans-serif;
    background-color: #154683;
    color: #fff;
`;

export const Cta = styled(Link)`
    ${CommonCtaStyle}
    background-color: #fff;
    color: #154683;
`;
