// =================== Finance page resources ==================

import RISK_INSIGHT_TEMPLATE from '../../images/product-assets/risk-insight-template.svg';
import ID_VERIFICATION_IMG from '../../images/product-assets/id-scan.svg';

import IFP_STEP_3 from '../../images/product-assets/ifp-step-3.svg';

import ICON_BAR from '../../images/Icons/icon-graph.svg';
import ICON_PAPER from '../../images/Icons/icon-paper-with-graph.svg';
import ICON_USER_FRAME from '../../images/Icons/icon-user-frame.svg';

import ICON_DOLLAR from '../../images/Icons/icon_dollar-rounded.svg';
import ICON_SHIELD from '../../images/Icons/icon_security-shield.svg';

import ID_FRAUD_IMG_1 from '../../images/product-assets/id-fraud-img-1.svg';
import ID_FRAUD_IMG_2 from '../../images/product-assets/id-fraud-img-2.svg';

import FRAUD_PREVENTION_IMG_1 from '../../images/product-assets/finance-fraud-prevention-img-1.svg';
import FRAUD_PREVENTION_IMG_2 from '../../images/product-assets/finance-fraud-prevention-img-2.svg';

import FINANCE_BLOG_IMG from '../../images/product-assets/finance-blog-resource-img.svg';
import FINANCE_SOLUTION_IMG from '../../images/product-assets/finance-solution-resource-img.svg';
import { COMMON_KB_RESOURCE } from '../../helper/constant';

// ======================= FINANCE PAGE UTILITIES =======================

export const AUTHENTICATION_STEPS = [
    {
        id: 1,
        icon: ICON_BAR,
        heading: 'Get Behavioral Insights',
        desc: 'Verify identities by matching contact data to our identity graph, with confidence scores and detailed insights.',
        points: [
            'Confidence Scores on User PII',
            'Social Profile Data with IP Address Validation',
            'Flag Suspicious Activities and Assess Risk',
        ],
        cta: {
            text: 'Learn More',
            link: 'https://info.authenticate.com/user-insights',
        },
        img: RISK_INSIGHT_TEMPLATE,
    },
    {
        id: 2,
        icon: ICON_PAPER,
        heading: 'Perform User Verification',
        desc: 'Verify 7,500+ government IDs from 200+ countries in 30 seconds, analyzing facial landmarks for KYC onboarding.',
        points: [
            'Financial Account Ownership Verification',
            'Government ID Verification with Liveness and Face Match',
            'Remote Onboarding with Global KYC',
        ],
        cta: {
            isPopup: true,
            text: 'Schedule a Demo',
        },
        img: ID_VERIFICATION_IMG,
    },
    {
        id: 3,
        icon: ICON_USER_FRAME,
        heading: 'Access Screening Reports',
        desc: 'Access detailed PDF reports with data on criminal, education, employment, and vehicle records, plus PEP screening.',
        points: [
            'Global Watchlist Search',
            'PEP and US Sanctions List Search',
            'Active Crime Monitoring',
        ],
        cta: {
            text: 'Get Started',
            link: 'https://portal.authenticate.com/register?utm_source=industry&utm_medium=finance&utm_campaign=scroll',
        },
        img: IFP_STEP_3,
    },
];

export const ID_FRAUDS = {
    DATA_1: {
        icon: ICON_SHIELD,
        heading: 'AI & ML Fraud is Rising',
        desc: `Did you know that every day, 400 companies in North America are targeted by deepfake fraud? Is your business prepared to handle this growing threat? Scammers are now using synthetic IDs and deepfakes—they've gotten smarter, and so have we.`,
        points: [
            'Over 200 Datapoints Analysed',
            'Liveness Checks',
            'Real-Time Forensic Analysis',
            'Email, Phone and Address Verification',
        ],
        img: ID_FRAUD_IMG_1,
    },
    DATA_2: {
        icon: ICON_DOLLAR,
        heading: 'KYC/AML Compliance is Expensive ',
        desc: `But it doesn't need to be! You get the most accurate and authentic data-driven solutions that won't break the bank.  The highest level of protection with i-Beta Level 2 spoof detection and the most comprehensive data protection and compliance standards.`,
        points: [
            'No Long-Term Contracts',
            'Global Coverage',
            'No Monthly minimums',
            'SOC II, HIPPA & GDPR Compliant',
        ],
        img: ID_FRAUD_IMG_2,
    },
};

export const ID_FRAUD_PREVENTIONS = {
    DATA_1: {
        id: 1,
        heading: 'AML Screening with Deep Background Checks',
        subText:
            'AML screening with in-depth background checks to prevent fraud & enhance security.',
        img: FRAUD_PREVENTION_IMG_1,
        points: [
            'Screens for sanctions, PEPs, & media watchlists',
            'Verifies identities in real-time during onboarding',
            'Provides checks throughout the customer lifecycle',
        ],
        cta: {
            isPopup: true,
            text: 'Schedule a Demo',
        },
    },
    DATA_2: {
        id: 2,
        heading: 'Customer Onboarding and Instant KYC',
        subText:
            'With rising fraud and remote onboarding demands, banks urgently require secure verification solutions.',
        img: FRAUD_PREVENTION_IMG_2,
        points: [
            'Uses AI, computer vision, machine learning',
            'Reliably verifies remote users',
            'Reduces fraud risk, streamlines onboarding',
        ],
        cta: {
            text: 'Learn More',
            link: '/solutions/kyc-aml-compliance',
        },
    },
};

export const FINANCE_PAGE_RESOURCES = [
    {
        id: 1,
        link: 'https://authenticate.com/resources/blog/customer-onboarding',
        banner: FINANCE_BLOG_IMG,
        tag: 'Blog',
        tagColor: '#B54708',
        tagBackgroundColor: '#FFFAEB',
        title: 'Simplify Customer Onboarding with Advanced ID Verification & KYC Compliance',
        description:
            'Discover how Authenticate® enhances your customer onboarding process by resolving common pain points with advanced ID verification and KYC compliance solutions, leading to increased customer satisfaction and business growth.',
        cardType: 'blog',
    },
    {
        id: 2,
        link: 'https://authenticate.com/solutions/kyc-aml-compliance/',
        banner: FINANCE_SOLUTION_IMG,
        tag: 'Solutions',
        tagColor: '#C11574',
        tagBackgroundColor: '#FDF2FA',
        title: 'KYC and AML Compliance Solution',
        description:
            'Simplify Your KYC/AML Compliance with Authenticate: Verify Users Instantly, Prevent Fraud, and Fulfill All Compliance Needs Effortlessly.',
        cardType: 'solutions',
    },
    COMMON_KB_RESOURCE,
];

export const FINANCE_PAGE_FAQS = [
    {
        id: 0,
        title: 'How does Authenticate streamline customer onboarding for financial institutions?',
        desc: `Authenticate enhances customer onboarding by leveraging global identity verification and advanced AML screening to provide a seamless experience. It verifies over 7,500 types of government-issued IDs from 200 countries in just 30 seconds. Utilizing AI and machine learning, it analyzes facial landmarks for liveness and face matching, reducing fraud risk while expediting the onboarding process.`,
    },
    {
        id: 1,
        title: 'What methods does Authenticate use for identity verification and fraud prevention?',
        desc: 'Authenticate employs a multi-layered approach to identity verification and fraud prevention. It analyzes emails, phone numbers, and social profiles against a proprietary identity graph to generate a confidence score out of 99. Real-time forensic analysis examines over 200 data points to detect synthetic IDs and deepfakes, while liveness checks and face matching ensure the user’s live presence and identity.',
    },
    {
        id: 2,
        title: 'How does Authenticate assist with AML compliance and screening?',
        desc: 'Authenticate offers comprehensive AML screening solutions by providing global watchlist searches against over 50 national and international sanction lists. It identifies Politically Exposed Persons and individuals on U.S. sanctions lists, and offers active crime monitoring. Detailed PDF reports cover criminal, education, employment, and motor vehicle records, helping institutions meet regulatory obligations and reduce fraud risk.',
    },
    {
        id: 3,
        title: `How to integrate Authenticate's solutions into existing systems?`,
        desc: 'Integration with Authenticate is designed to be seamless and user-friendly. The no-code portal allows for quick setup and onboarding without the need for a development team. For advanced setups, robust APIs provide seamless integration options. Businesses can set up within minutes and start protecting themselves from fraud immediately, whether they have technical expertise or not.',
    },
];
