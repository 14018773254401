import styled from 'styled-components';
import { breakpoints } from '../../../../styles/screenSizes';
import GRADIENT_BG from '../../../../images/product-assets/fraud-prevention-bg-img.png';

export const Wrapper = styled.section`
    margin-top: 102px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: ${breakpoints.md}) {
        margin-top: 72px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        margin-top: 54px;
    }
`;

export const Container = styled.div`
    max-width: 1280px;
    margin: auto;
`;

export const Data = styled.div`
    margin: 0 24px;
    padding: 54px 45px;
    border-radius: 24px;
    background-image: url(${GRADIENT_BG}), linear-gradient(113.6deg, #0077b9 -6.18%, #013162 84.8%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    @media only screen and (max-width: ${breakpoints.md}) {
        padding: 26px 16px;
        border-radius: 16px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        margin: 0 16px;
    }
`;

export const Heading = styled.h1`
    font-size: 42px;
    line-height: 50.4px;
    color: #fff;
    font-weight: 500;
    max-width: 50%;
    text-align: center;
    margin: auto;

    @media only screen and (max-width: ${breakpoints.lg}) {
        font-size: 30px;
        line-height: 37.5px;
        max-width: 60%;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        font-size: 24px;
        line-height: 30px;
        max-width: 90%;
    }
`;

export const Logo = styled.img`
    height: 34px;
    width: auto;

    @media only screen and (max-width: ${breakpoints.xl}) {
        height: 24px;
        margin-top: 5px;
    }

    @media only screen and (max-width: ${breakpoints.lg}) {
        margin-top: 8px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        height: 20px;
    }
`;

export const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 36px;
    margin-top: 48px;
    z-index: 10;

    @media only screen and (max-width: ${breakpoints.md}) {
        margin-top: 40px;
        gap: 16px;
    }

    @media only screen and (max-width: ${breakpoints.sm}) {
        grid-template-columns: 1fr;
    }
`;
