import React, { useState } from 'react';
import Thumbnail from '../../../../images/product-assets/finance-whitepaper-thumbnail.png';
import { Container, DownloadButton, ImgContainer, Text } from './financeWhitepaperResponse.style';
import DownloadIcon from '../../../../images/Icons/at_download-02-filled.svg';
import PropTypes from 'prop-types';
import { whitepaperStates } from '../../../../components/reusable/forms/Whitepaper/constant';
import { FormHeading } from '../../../../components/reusable/forms/Whitepaper/whitepaper.style';

const FinanceWhitepaperResponse = ({ data, error }) => {
    const [downloading, setDownloading] = useState(false);
    const [pdfDownloaded, setPdfDownloaded] = useState(false);

    const handleDownload = async () => {
        try {
            setDownloading(true);

            const response = await fetch(data?.pdfURL);
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = 'authenticate-finance-whitepaper.pdf';
            document.body.appendChild(link);
            link.click();
            setPdfDownloaded(true);

            // Clean up
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            setDownloading(false);
        } catch (error) {
            console.error('Error during download:', error);
            setDownloading(false);
        }
    };

    const getWhitepaperState = () => {
        if (data) {
            if (data?.isValidEmail && pdfDownloaded) {
                return 'downloadComplete';
            } else if (data?.isValidEmail) {
                return 'downloadReady';
            } else {
                return 'spamEmail';
            }
        } else if (error) {
            return 'rateLimitedApi';
        }
    };

    return (
        <Container>
            <FormHeading>{whitepaperStates[getWhitepaperState()].title}</FormHeading>
            <ImgContainer>
                <img src={Thumbnail} alt="Whitepaper thumbnail" draggable="false" />
            </ImgContainer>
            <Text>{whitepaperStates[getWhitepaperState()].desc}</Text>
            {whitepaperStates[getWhitepaperState()]?.download && (
                <DownloadButton type="button" onClick={handleDownload}>
                    {downloading ? (
                        'Downloading...'
                    ) : (
                        <>
                            <p>Download</p>
                            <img src={DownloadIcon} alt="downloadIcon" />
                        </>
                    )}
                </DownloadButton>
            )}
        </Container>
    );
};

FinanceWhitepaperResponse.propTypes = {
    data: PropTypes.object.isRequired,
    error: PropTypes.any,
};

export default FinanceWhitepaperResponse;
