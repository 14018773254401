import React, { useEffect, useRef } from 'react';
import {
    Bullet,
    Card,
    ContactCta,
    Content,
    Cta,
    CtaLabel,
    Details,
    Heading,
    IconContainer,
    Para,
    Points,
    StepImageContainer,
    Text,
} from './responsiveAuthenticationSteps.style';
import CheckMarkIcon from '../../../../images/Icons/icon-checkmark-styled.svg';
import ArrowRight from '../../../../images/Icons/at_chevron-right.svg';
import PropTypes from 'prop-types';
import { useModal } from '../../../../context/ModalContext';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const ResponsiveAuthenticationSteps = ({ items }) => {
    const { openModal } = useModal();
    const cardsRef = useRef([]);

    useEffect(() => {
        cardsRef.current?.forEach((card, index) => {
            const direction = index % 2 === 0 ? 60 : -60;

            gsap.fromTo(
                card,
                { x: direction, opacity: 0 },
                {
                    x: 0,
                    opacity: 1,
                    duration: 0.6,
                    ease: 'none',
                    scrollTrigger: {
                        trigger: card,
                        start: 'top 80%',
                    },
                }
            );
        });
        return () => ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    }, [items]);

    return items?.map((step, index) => (
        <Card key={step.id + index} ref={(el) => (cardsRef.current[index] = el)}>
            <StepImageContainer>
                <img src={step.img} alt={'step - ' + step.id} />
            </StepImageContainer>
            <Content>
                <IconContainer>
                    <img src={step.icon} alt="icon" />
                </IconContainer>
                <Details>
                    <Heading>{step.heading}</Heading>
                    <Para>{step.desc}</Para>
                    {step.points.map((point, index) => (
                        <Points key={index + point?.slice(0, 20)}>
                            <Bullet>
                                <img src={CheckMarkIcon} alt="bullet" />
                            </Bullet>
                            <Text>{point}</Text>
                        </Points>
                    ))}
                    {step?.cta?.isPopup ? (
                        <ContactCta onClick={() => openModal('contact')}>
                            <CtaLabel>{step?.cta?.text}</CtaLabel>
                            <img src={ArrowRight} alt="arrow-right" />
                        </ContactCta>
                    ) : (
                        <Cta href={step?.cta?.link} target="_blank" rel="noreferrer">
                            <CtaLabel>{step?.cta?.text}</CtaLabel>
                            <img src={ArrowRight} alt="arrow-right" />
                        </Cta>
                    )}
                </Details>
            </Content>
        </Card>
    ));
};

ResponsiveAuthenticationSteps.propTypes = {
    items: PropTypes.array,
};

export default ResponsiveAuthenticationSteps;
